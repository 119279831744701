<template>
    <div class="NotFoundAll">

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.NotFoundAll{
    background-image: url(../assets/img/404.png);
    background-repeat: no-repeat;
    background-position: center center;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
}
</style>